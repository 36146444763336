// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import axios from 'axios';
// import moment from 'moment';
// import { AgGridReact } from 'ag-grid-react';
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// import { API_BASE } from '../../env';
// import {
//   IconButton,
//   TextField,
//   Tooltip,
//   Button,
//   Chip,
// } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import {
//   GetAppOutlined,
//   HourglassEmptyOutlined,
//   Search,
// } from '@material-ui/icons';

// const useStyles = makeStyles({
//   // Custom styles for the TextField to match the button height
//   inputRoot: {
//     height: 50,
//     padding: 0,
//   },
//   input: {
//     height: '100%',
//     boxSizing: 'border-box',
//     padding: '0 14px',
//   },
// });

// const DispatchReviewPage = () => {
//   const classes = useStyles();

//   const [rowData, setRowData] = useState([]);
//   const [filteredRowData, setFilteredRowData] = useState([]);
//   const gridRef = useRef();
//   const [loading, setLoading] = useState(false);
//   const [searchInput, setSearchInput] = useState('');
//   const [searchTerms, setSearchTerms] = useState([]);

//   const columnDefs = [
//     { headerName: 'NÚMERO DE VIN', field: 'vin_number' },
//     { headerName: 'MODELO', field: 'model' },
//     { headerName: 'KILOMETROS (KM)', field: 'km' },
//     {
//       headerName: 'IMAGEN',
//       cellRendererFramework: params => (
//         <a
//           href={params.data.image}
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Ver Imagen
//         </a>
//       ),
//     },
//     {
//       headerName: 'FECHA',
//       field: 'date',
//       valueFormatter: params => moment(params.value).local().format('DD/MM/YYYY hh:mm'),
//     },
//     { headerName: 'OBSERVACIONES', field: 'remark' },
//   ];

//   const handleGetData = async () => {
//     const config = {
//       headers: {
//         "Content-Type": "application/json"
//       }
//     };

//     const token = localStorage.getItem("token");

//     config.headers["Authorization"] = `Token ${token}`;

//     try {
//       const response = await axios.get(`${API_BASE}v1/dispatches-review/`, config);
//       setRowData(response.data);
//       setFilteredRowData(response.data); // Initialize filtered data
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   }

//   const handleDownload = async () => {

//     setLoading(true)
//     const config = {
//       responseType: "blob",
//       headers: { "Content-Type": "application/json" }
//     };
//     const token = localStorage.getItem("token");

//     config.headers["Authorization"] = `Token ${token}`;

//     await axios
//       .get(`${API_BASE}v1/dispatches-review/download/`, config)
//       .then(response => {
//         let blob = new Blob([response.data], {
//           type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
//         });

//         let fileURL = window.URL.createObjectURL(blob);
//         let fileLink = document.createElement("a");
//         fileLink.href = fileURL;

//         fileLink.download = "ReporteDespacho.xlsx";
//         fileLink.click();
//       })
//       .catch(error => {
//         console.log(error.response.data);
//       })
//       .finally(() => {
//         setLoading(false)
//       });
//   };

//   useEffect(() => {
//     handleGetData();
//   }, []);

//   // Handle input change
//   const handleInputChange = (event) => {
//     setSearchInput(event.target.value);
//   };

//   // Handle key press in the input field
//   const handleKeyDown = (event) => {
//     if (event.key === 'Enter' && searchInput.trim() !== '') {
//       // Prevent form submission if inside a form
//       event.preventDefault();
//       // Add the term to the search terms array
//       if (!searchTerms.includes(searchInput.trim())) {
//         setSearchTerms([...searchTerms, searchInput.trim()]);
//       }
//       setSearchInput('');
//     }
//   };

//   // Remove a search term
//   const handleDeleteChip = (chipToDelete) => () => {
//     setSearchTerms((chips) => chips.filter((chip) => chip !== chipToDelete));
//   };

//   // Function to trigger the grid to re-filter when the "Buscar" button is clicked
//   const handleSearch = () => {
//     if (searchTerms.length === 0) {
//       setFilteredRowData(rowData);
//       return;
//     }
//     const filteredData = rowData.filter((row) => {
//       const rowString = Object.values(row).join(' ').toLowerCase();
//       return searchTerms.some((term) =>
//         rowString.includes(term.toLowerCase())
//       );
//     });
//     setFilteredRowData(filteredData);
//   };

//   // Keep the existing quick filter functionality
//   const onFilterTextBoxChanged = useCallback(() => {
//     gridRef.current.api.setQuickFilter(
//       document.getElementById('filter-text-box-dr').value
//     );
//   }, []);

//   return (
//     <div style={{ display: 'grid' }}>
//       <div style={{
//         display:'flex',
//         justifyContent:'space-between',
//         marginBottom: '20px',
//         alignItems: 'flex-start', // Align items to the top
//         }}
//       >
//         <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
//           {/* Input field for new search terms */}
//           <TextField
//             variant="outlined"
//             label="Buscar"
//             placeholder="Escriba y presione Enter"
//             value={searchInput}
//             onChange={handleInputChange}
//             onKeyDown={handleKeyDown}
//             style={{ width: '100%', marginBottom: 5 }}
//             InputProps={{
//               classes: {
//                 root: classes.inputRoot,
//                 input: classes.input,
//               },
//             }}
//           />
//           {/* Display the chips */}
//           <div style={{ display: 'flex', flexWrap: 'wrap' }}>
//             {searchTerms.map((term, index) => (
//               <Chip
//                 key={index}
//                 label={term}
//                 onDelete={handleDeleteChip(term)}
//                 style={{ marginRight: 5, marginBottom: 5 }}
//               />
//             ))}
//           </div>
//         </div>

//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleSearch}
//           style={{ height: 40 }}
//           startIcon={<Search />} // Add the magnifying glass icon
//         >
//           Buscar
//         </Button>

//         <>
//           <Tooltip title="Descargar">
//             <IconButton
//               onClick={() => handleDownload()}
//               disabled={loading}
//             >
//               {(loading) ? <HourglassEmptyOutlined/> : <GetAppOutlined />}
//             </IconButton>
//           </Tooltip>
//         </>
//       </div>
//       {/* Keep the existing quick filter input */}
//       {/* <div style={{ marginBottom: '20px' }}>
//         <TextField
//           label="Filtro Rápido"
//           id='filter-text-box-dr'
//           name="schoolName"
//           type="text"
//           variant="outlined"
//           pattern="text"
//           size="small"
//           onChange={onFilterTextBoxChanged}
//           InputLabelProps={{
//             shrink: true
//           }}
//           placeholder={'Buscar...'}
//         />
//       </div> */}
//       <div className="ag-theme-alpine" style={{ height: 600, width: '100wh' }}>
//         <AgGridReact
//           ref={gridRef}
//           rowData={filteredRowData}
//           columnDefs={columnDefs}
//           pagination={true}
//           paginationPageSize={50}
//         />
//       </div>
//     </div>
//   );
// };

// export default DispatchReviewPage;


import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { API_BASE } from '../../env';
import {
  IconButton,
  TextField,
  Tooltip,
  Button,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  GetAppOutlined,
  HourglassEmptyOutlined,
  Search,
  FileCopyOutlined, // Icon for the paste button
} from '@material-ui/icons';

const useStyles = makeStyles({
  // Custom styles for the TextField to match the button height
  inputRoot: {
    height: 50,
    padding: 0,
  },
  input: {
    height: '100%',
    boxSizing: 'border-box',
    padding: '0 14px',
  },
});

const DispatchReviewPage = () => {
  const classes = useStyles();

  const [rowData, setRowData] = useState([]);
  const [filteredRowData, setFilteredRowData] = useState([]);
  const gridRef = useRef();
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchTerms, setSearchTerms] = useState([]);

  const columnDefs = [
    { headerName: 'NÚMERO DE VIN', field: 'vin_number' },
    { headerName: 'MODELO', field: 'model' },
    { headerName: 'KILOMETROS (KM)', field: 'km' },
    {
      headerName: 'IMAGEN',
      cellRendererFramework: params => (
        <a
          href={params.data.image}
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver Imagen
        </a>
      ),
    },
    {
      headerName: 'FECHA',
      field: 'date',
      valueFormatter: params => moment(params.value).local().format('DD/MM/YYYY hh:mm'),
    },
    { headerName: 'OBSERVACIONES', field: 'remark' },
  ];

  const handleGetData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    try {
      const response = await axios.get(`${API_BASE}v1/dispatches-review/`, config);
      setRowData(response.data);
      setFilteredRowData(response.data); // Initialize filtered data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDownload = async () => {
    setLoading(true);
    const config = {
      responseType: "blob",
      headers: { "Content-Type": "application/json" }
    };
    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    await axios
      .get(`${API_BASE}v1/dispatches-review/download/`, config)
      .then(response => {
        let blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
        });

        let fileURL = window.URL.createObjectURL(blob);
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;

        fileLink.download = "ReporteDespacho.xlsx";
        fileLink.click();
      })
      .catch(error => {
        console.log(error.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetData();
  }, []);

  // Handle input change
  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  // Handle key press in the input field
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && searchInput.trim() !== '') {
      event.preventDefault(); // Prevent form submission if inside a form
      if (!searchTerms.includes(searchInput.trim())) {
        setSearchTerms([...searchTerms, searchInput.trim()]);
      }
      setSearchInput('');
    }
  };

  // Remove a search term
  const handleDeleteChip = (chipToDelete) => () => {
    setSearchTerms((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  // Function to trigger the grid to re-filter when the "Buscar" button is clicked
  const handleSearch = () => {
    if (searchTerms.length === 0) {
      setFilteredRowData(rowData);
      return;
    }
    const filteredData = rowData.filter((row) => {
      const rowString = Object.values(row).join(' ').toLowerCase();
      return searchTerms.some((term) =>
        rowString.includes(term.toLowerCase())
      );
    });
    setFilteredRowData(filteredData);
  };

  // Function to handle pasting search terms
  const handlePaste = async () => {
    if (navigator.clipboard && navigator.clipboard.readText) {
      try {
        const text = await navigator.clipboard.readText();
        if (text.trim() !== '') {
          const terms = text.trim().split(/\s+/);
          const newTerms = terms.filter(
            (term) => term.trim() !== '' && !searchTerms.includes(term.trim())
          );
          if (newTerms.length > 0) {
            setSearchTerms([...searchTerms, ...newTerms]);
          }
        }
      } catch (err) {
        console.error('No se pudo leer el contenido del portapapeles: ', err);
      }
    }
  };

  return (
    <div style={{ display: 'grid' }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        alignItems: 'flex-start', // Align items to the top
      }}
      >
        <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
          {/* Input field for new search terms */}
          <TextField
            variant="outlined"
            label="Buscar"
            placeholder="Escriba y presione Enter"
            value={searchInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            style={{ width: '100%', marginBottom: 5 }}
            InputProps={{
              classes: {
                root: classes.inputRoot,
                input: classes.input,
              },
            }}
          />
          {/* Display the chips */}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {searchTerms.map((term, index) => (
              <Chip
                key={index}
                label={term}
                onDelete={handleDeleteChip(term)}
                style={{ marginRight: 5, marginBottom: 5 }}
              />
            ))}
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            style={{ height: 40 }}
            startIcon={<Search />}
          >
            Buscar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePaste}
            style={{ height: 40, marginLeft: 10 }}
            startIcon={<FileCopyOutlined />}
          >
            Cargar
          </Button>
        </div>

        <>
          <Tooltip title="Descargar">
            <IconButton
              onClick={() => handleDownload()}
              disabled={loading}
            >
              {(loading) ? <HourglassEmptyOutlined /> : <GetAppOutlined />}
            </IconButton>
          </Tooltip>
        </>
      </div>

      <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={filteredRowData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={50}
        />
      </div>
    </div>
  );
};

export default DispatchReviewPage;
